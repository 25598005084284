// Colors
$white:    #fff !default;
$gray-100: #f4f5f7 !default;   //$gray-100: #f6f9fc !default;
$gray-200: #f8fafc !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #8898aa !default;   // Line footer color
$gray-700: #525f7f !default;   // Line p color
$gray-800: #32325d !default;   // Line heading color
$gray-900: #212529 !default;
$black:    #000 !default;

$blue:    #3F59FF !default;
$indigo:  #5603ad !default;
$purple:  #8965e0 !default;
$pink:    #f3a4b5 !default;
$red:     #FF4451 !default;
$orange:  #fb6340 !default;
$yellow:  #FBAD40 !default;
$green:   #3BA549 !default;
$teal:    #11cdef !default;
$cyan:    #1ACAA1 !default;

$default:       #172b4d !default;
$primary:       $cyan !default;
$secondary:     #626976 !default;
$success:       $green !default;
$info:          $blue !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-200 !default;
$dark:          $gray-900 !default;
$darker:        darken($gray-900, 15%) !default;

//$dropdown-link-hover-color: $white !default;
/*$dropdown-link-hover-bg: $secondary !default;
$dropdown-link-active-color: $white !default;
$dropdown-link-active-bg: $primary !default;*/

//$input-line-height: 1.125 !default;
$input-color: $default !default;
$input-border-color: $gray-400 !default;
$input-group-addon-color: $default !default;

$table-head-bg: $gray-100 !default;
$font-google: 'Inter' !default;
$font-local: null !default;
