@import "qard-variables";
@import "~@tabler/core";
@import "~@tabler/core/dist/css/tabler-flags.min.css";
@import "~@tabler/core/node_modules/@tabler/icons/iconfont/tabler-icons.scss";
@import "~flatpickr/dist/themes/material_green.css";
@import "@yaireo/tagify/src/tagify.scss";

body {
  height: 100%;
  overflow-y: auto;

  a {
    text-decoration: none !important;
  }
}

.badge {
  padding: 0.25em;
}

.filter-box {
  display: none;
}

.filter:not(.filter-on) {
  display: none;
}

.spin {
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

.container-medium {
  max-width: 40rem !important;
}

.refresh-health-check:hover, .refresh-health-check:focus{
  box-shadow: none !important;
}

.badge {
  user-select: auto !important;
}

.page-title .badge {
  font-size: 70%;
}

#rncs-api-data-container th {
  font-size: 0.875rem;
  text-transform: lowercase;
}

.progress.health-check-history {
  height:3rem;
  align-items: center;
  flex-flow: row-reverse;

  .progress-bar {
    border-style: solid;
    border-radius: 5px;
    border-width: 0;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 3px;
    max-height: 90%;
    height: 100%;
    min-width: 6px;
    width: 6px;
    //flex: 1 0 0%;
  }
}

.tagify{
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #ced4da;
}

.form-check-input.form-check-input-negative:checked {
  background-color: #FF4451 !important;
}

td > .form-check {
  margin-bottom: 0;
}

.bg-blue-dark {
  background: linear-gradient(139.97deg, #0A305A 22.82%, #041F3D 73.64%);
}

.form-control[readonly] {
  background-color: #FFFFFF !important;
}

.table tr:last-child > td {
  border-width: 0;
}

.copyable:hover {
  color: $body-color;
}
.copyable {
  color: $text-muted;
  display: inline-block;
  cursor: pointer;
}
@keyframes copied {
  0% {
    color: $primary;
    transform: scale(1);
  }
  50% {
    color: $primary;
    transform: scale(1.25);
  }
  100% {
    color: inherit;
    transform: scale(1);
  }
}
.copied {
  animation: copied 0.5s;
}

.data-container {
  height: 100%;
  max-height: 350px;
  overflow-y: auto;
}

@media (min-width: 992px) {
  #modal-impots-connection {
    &>.modal-lg, &>.modal-xl {
      max-width: 890px;
    }
  }
}

#alert-icon-impots {
  font-size:  2.25rem;
}

.connection-close {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  z-index: 20;

  .icon {
    width: 2rem !important;
    height: 2rem !important;
    font-size: 1.25rem;
    vertical-align: bottom;
    stroke-width: 1 !important;
  }
}


@mixin make-min-widths($breakpoints: $grid-breakpoints) {
  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    @include media-breakpoint-up($breakpoint, $breakpoints) {

    }
  }
}
